




















import Vue from 'vue';

import TopHeaderContainer from '~/containers/TopHeaderContainer/TopHeaderContainer.vue';
import TheFooterContainer from '~/containers/TheFooterContainer.amp';
import LoginWindow from '~/components/LoginWindow/LoginWindow.vue';
import SafariPushWindowContainer from '~/containers/SafariPushWindowContainer/SafariPushWindowContainer.vue';
import NavMenuContainer from '~/containers/NavMenuContainer/NavMenuContainer.vue';
import DarkBackgroundContainer from '~/containers/DarkBackgroundContainer.vue';
import GoogleOneTapAuth from '~/components/GoogleOneTapAuth/GoogleOneTapAuth.vue';

export default Vue.extend({
  name: 'SimpleLayout',
  components: {
    TopHeaderContainer,
    TheFooterContainer,
    LoginWindow,
    SafariPushWindowContainer,
    NavMenuContainer,
    DarkBackgroundContainer,
    GoogleOneTapAuth,
  },
  data: () => ({
    searchString: '' as string | (string | null)[],
  }),
});
