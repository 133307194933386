






import Vue from 'vue';
import DefaultAmpLayout from './default.amp.vue';

export default Vue.extend({
  name: 'HealthAmpLayout',

  components: { DefaultAmpLayout },
});
